import { ROUTES } from "const";
import { courseService, learningLiveService } from "libs";

export class ABILITAZIONE60CFU {

  static async getDashboardCards(courseCode, registrationCode) {
    let cards = this.getFixedCards();

    try {

   
    } catch (error) { }

    return cards;
  }

  static getFixedCards() {
    return [
      {
        title: "Consulta la graduatoria",
        link: ROUTES.ABILITAZIONE3060_ENROLLMENT_RESULT,
        img: "/img/icon/message.png",
        className: "image-box",
      },
    ];
  }

  static getDashboardNotices(courseCode, competitionClass) {
    let notices = [
     
    ];

    return notices;
  }
}
